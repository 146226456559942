
import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {SERVER_API_URL} from '../../app.constants';
import {MatDialog} from "@angular/material/dialog";

@Injectable({providedIn: 'root'})
export class AuthServerProvider {

    constructor(private http: HttpClient,
                private dialog: MatDialog) {
    }

    login(credentials): Promise<Object | undefined> {
        let data = 'j_username=' + encodeURIComponent(credentials.username) +
            '&j_password=' + encodeURIComponent(credentials.password) +
            '&remember-me=' + credentials.rememberMe + '&submit=Login';

        if (credentials.verificationCode) {
           data = data.concat('&code=' + encodeURIComponent(credentials.verificationCode));
        }

        const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');

        return this.http.post(SERVER_API_URL + 'api/authentication', data, {headers}).toPromise();
    }

    logout(): Observable<any> {
        // logout from the server
        this.dialog.closeAll();
        return this.http.post(SERVER_API_URL + 'api/logout', {}, {observe: 'response'}).pipe(map((response: HttpResponse<any>) => {
            // to get a new csrf token call the api
            this.http.get(SERVER_API_URL + 'api/account').subscribe(() => {
            }, () => {
            });
            return response;
        }));
    }
}
